// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aviator-jsx": () => import("./../../../src/pages/aviator.jsx" /* webpackChunkName: "component---src-pages-aviator-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-defenderx-jsx": () => import("./../../../src/pages/defenderx.jsx" /* webpackChunkName: "component---src-pages-defenderx-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-medic-ii-jsx": () => import("./../../../src/pages/medicII.jsx" /* webpackChunkName: "component---src-pages-medic-ii-jsx" */),
  "component---src-pages-medic-jsx": () => import("./../../../src/pages/medic.jsx" /* webpackChunkName: "component---src-pages-medic-jsx" */)
}

